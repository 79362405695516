import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
} from "@material-ui/core";

import { Link, useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bannerSection: {},
  updateSection: {
    padding: "90px 0",
    overflow: "hidden",
    position: "relative",
    backgroundImage: "url(./images/craft_banner.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 0",
    },
    "& .emailBox": {
      position: "relative",
      "& button": {
        background:
          "linear-gradient(91.94deg, #35A5F5 31.32%, #62D3F0 117.28%)",
        borderRadius: "44px 0px 0px 44px",
        color: "#fff",
        minWidth: "100px",
        height: "40px",
        top: "5px",
        right: "5px",
        position: "absolute",
      },
    },
    "& input": {
      backgroundColor: "#fff",
      borderRadius: "5px",
      border: " none",
      height: "50px",
      padding: "0 15px",
    },
    "& h1": {
      color: "#000000",
      display: "flex",
      fontSize: "50px",
      alignItems: "center",
      fontWeight: "600",
      "& img": {
        marginRight: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
      },
    },
    "& h4": {
      fontSize: "21px",
      fontWeight: "300",
      fontFamily: "Poppins",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  craft: {
    background: "linear-gradient(261.87deg, #62D3F0 13.12%, #35A5F5 83.57%)",
    border: "1.01471px solid #51ACED",
    boxShadow: "0px 4.05886px 4.05886px rgba(0, 0, 0, 0.25)",
    borderRadius: "10.1471px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20.2943px",
    lineHeight: "30px",
    color: "#FFFFFF !important",
  },
}));

export default function BestSeller(props) {
  const classes = useStyles();
  const history = useHistory();
  const [datacraft, setDatacraft] = useState();

  useEffect(() => {
    setDatacraft("craft");
  });

  return (
    <Box className={classes.bannerSection}>
      <Box className={classes.updateSection}>
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4} align="center"></Grid>
            <Grid item xs={12} sm={12} lg={8}>
              <Box>
                <Typography variant="h1" style={{ color: "#ffffff" }}>
                  About Us
                </Typography>
                <Box mt={1}>
                  <Typography variant="h4" style={{ color: "#ffffff" }}>
                    Mobiloitte can truly be your Digital Transformation Guide in
                    your journey to Digitise your: Marketing, Customer
                    Experience, Project Management, Human Resources, Support
                    Services, Assets management, MIS to name a few with our wide
                    array of services and solutions in Blockchain, IoT, AI,
                    BOTS, Mobile and Web Development
                  </Typography>
                </Box>

                <Box pt={4}>
                  <Button className={classes.craft}
                    target="_blank"
                    href="https://www.mobiloitte.com/request-for-quote/">
                    Contact us
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
