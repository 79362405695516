import React, { useState, useRef, useContext, useEffect } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  Link,
  IconButton,
  Dialog,
  Card,
  CardContent,
} from "@material-ui/core";

import { useHistory, Link as RouterLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaMediumM } from "react-icons/fa";
import { FiSend, FiTwitter, FiYoutube } from "react-icons/fi";
import { AiOutlineReddit, AiFillYoutube } from "react-icons/ai";

import Scroll from "react-scroll";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";

const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#fff",
    position: "relative",
    padding: "50px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    "& .copy": {
      borderTop: "1px solid #D0D0D0",
      padding: "10px 0",
      textAlign: "center",
      fontWeight: 300,
      fontSize: "12px",
      color: "#626262",
    },
    "& .shape": {
      position: "absolute",
      right: "20px",
      top: "50px",
      [theme.breakpoints.down("xs")]: {
        top: "50%",
      },
    },
    "& .shape2": {
      position: "absolute",
      left: "80px",
      top: "55px",
    },
    "& .shape3": {
      position: "absolute",
      left: "40px",
      top: "75px",
    },
    "& .shape4": {
      position: "absolute",
      left: "200px",
      bottom: "50px",
    },
    "& ul": {
      paddingLeft: "0",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
      },
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: " #1D1D1D",
        fontSize: "14px",
        "& svg": {
          marginRight: "10px",
          color: "#408FAC",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "#408FAC",
      fontSize: "15px",
    },
    "& p": {
      color: "#626262",
    },
    "& h6": {
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "20px",
      },
    },
    "& a": {
      color: "#1d1d1d",
      fontWeight: 400,
      textDecoration: "none",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      "&:AIML": {
        color: "#35a5f5",
        textDecoration: "none",
      },
    },
  },
  footerlogo: {
    Width: "160px",
    height: "40px",
  },
  dialogProfileDiv: {
    display: 'grid',
    justifyContent: 'center'
  },
  dialogCard: {
    padding: '29px 40px 35px 40px',
    minWidth: '486px'
  },
  inputBox: {
    display: 'flex',
    justifyContent: 'end',
    paddingBottom: '10px'
  },
  inputBoxButton: {
    width: '53px',
    height: '20px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFFFFF',
    borderRadius: '2px'
  },
  inputlabelName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  },
  inputlabelvalue: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#1D1D1D',
  },
  nameBorderdiv: {
    border: '1px solid #B3B3B3',
    height: '39px',
    width: '100%',
    padding: '0px 8px 8px',
  },
  profileImg: {
    width: '80px',
    height: '80px',
    borderRadius: '100%'
  },
  dialogProfileDiv: {
    display: 'grid',
    justifyContent: 'center'
  },
  dialogCard: {
    padding: '29px 40px 35px 40px',
    minWidth: '486px'
  },
  inputlabelDiv: {
    paddingBottom: '18px'
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  const { account, library, chainId } = useWeb3React();
  const [open, setOpen] = React.useState(false);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const address = localStorage.getItem("walletAddress");

  const handleCloseadress = () => {
    setAnchorEl3(null);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const toastmsg = () => {
    toast.warn("Please connect your wallet");
  };
  const toastmsgcreate = () => {
    toast.warn("Please connect your wallet");
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <img src="images/shape/shape-1.png" className="shape moveTop" />
        <img src="images/shape/shape-3.png" className="shape2 rotate" />
        <img src="images/shape/shape-2.png" className="shape3 moveLeft" />
        <img src="images/shape/shape-4.png" className="shape4 rotate" />
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <Box mr={8}>
                <Box mb={2}>
                  {" "}
                  <RouterLink to="/">
                    <img className={classes.footerlogo} src="images/logo.png" /> <br />
                  </RouterLink>
                </Box>
                <Typography variant="body1" component="small">

                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h6">Web Link</Typography>
                  <List>
                    <ListItem
                      style={{ cursor: "pointer" }}
                      //  to="/collections"
                      onClick={() => {
                        history.push({
                          pathname: "/my-collection",
                        });
                      }}
                    >
                      <Link target="_blank">
                        Collections
                      </Link>


                    </ListItem>

                    {/* <ListItem to="/ranking" >
                      Ranking
                    </ListItem> */}
                    {/* {account ? (
                      <ListItem
                        //  to="/activity" 
                        style={{ cursor: "pointer" }}
                      >
                        My Activity
                      </ListItem>
                    ) : (
                      <ListItem
                        // onClick={() => {
                        //   history.push({
                        //     pathname: "conect-wallet",
                        //     search: "myactivity",
                        //   });
                        // }}
                        style={{ cursor: "pointer" }}
                      >
                        My Activity
                      </ListItem>
                    )} */}
                    {/* <ListItem style={{ cursor: "pointer", }}>

                      Referral Program

                    </ListItem> */}
                    {/* <ListItem
                      style={{ cursor: "pointer" }}
                    //  to="/support-tickets" 
                    >
                      Support-Ticket
                    </ListItem> */}
                  </List>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h6">User Account</Typography>
                  <List>
                    <ListItem
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push({
                          pathname: "/my-collection",
                        });
                      }}
                    // to="my-mints"
                    >
                      <Link target="_blank">
                        My Collection
                      </Link>

                    </ListItem>

                    {/* <ListItem to="create" style={{ cursor: "pointer" }}>
                      Create Items
                    </ListItem> */}

                    {/* <ListItem
                    //  to="create-nfttype" 
                    >
                      Create Physical NFT
                    </ListItem> */}
                    <div>
                      <ListItem
                        style={{ cursor: "pointer" }}
                      // to="profile"
                      >
                        <Link target="_blank"
                          onClick={handleClickOpen}
                        >
                          My Account
                        </Link>
                      </ListItem>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        onFocus={handleCloseadress}
                      >
                        <Card className={classes.dialogCard} >
                          <CardContent style={{ padding: '0px' }}>
                            <Box>
                              <div
                                className={classes.dialogProfileDiv}>
                                <img src="/images/AccountProfile.jpg" alt="Profile"
                                  className={classes.profileImg}
                                />
                              </div>
                              <Box className={classes.inputBox} >
                                {/* <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.inputBoxButton}
                              >
                                Edit
                              </Button> */}
                              </Box>
                            </Box>
                            <Box >
                              <div className={classes.inputlabelDiv} >
                                <label className={classes.inputlabelName}>
                                  First Name
                                </label>
                                {/* <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                name="Coco"
                                value="Coco"
                                lassName={classes.inputlabelvalue}
                              /> */}
                                <div className={classes.nameBorderdiv}>
                                  <p className={classes.inputlabelvalue}>Coco</p>
                                </div>
                              </div>
                              <div className={classes.inputlabelDiv}>
                                <label className={classes.inputlabelvalue}>
                                  Last Name
                                </label>
                                {/* <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                name="Caktux"
                                value="Caktux"
                                className={classes.inputlabelvalue}
                              /> */}
                                <div className={classes.nameBorderdiv}>
                                  <p className={classes.inputlabelvalue}>Caktux</p>
                                </div>
                              </div>
                              <div className={classes.inputlabelDiv} >
                                <label className={classes.inputlabelName}>
                                  Account Address
                                </label>
                                {/* <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                name="otp"
                                value={address}
                                lassName={classes.inputlabelvalue}
                              /> */}
                                <div className={classes.nameBorderdiv}>
                                  <p className={classes.inputlabelvalue}>{address}</p>
                                </div>
                              </div>
                            </Box>
                          </CardContent>
                        </Card>
                        {/* <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Disagree
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus>
                          Agree
                        </Button>
                      </DialogActions> */}
                      </Dialog>
                    </div>

                  </List>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h6">Support</Typography>
                  <List>
                    <ListItem
                      //  to="/faqs" 
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push({
                          pathname: "/faqs",
                        });
                      }}
                    >
                      <Link target="_blank">
                        FAQs
                      </Link>
                    </ListItem>
                    <ListItem
                      style={{ cursor: "pointer" }}

                    >
                      <Link target="_blank"
                        href="https://www.mobiloitte.com/contact-us/">
                        Help Center
                      </Link>
                    </ListItem>
                    {/* <ListItem
                      style={{ cursor: "pointer" }}
                    // to="/feedback" 
                    >
                      Feedback
                    </ListItem> */}
                  </List>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h6">Other</Typography>
                  <List>
                    <ListItem
                      style={{ cursor: "pointer" }}
                      target="_blank"
                      href="https://www.mobiloitte.com/about-us/"
                    >
                      <Link target="_blank"
                        href="https://www.mobiloitte.com/">
                        About Us
                      </Link>

                    </ListItem>
                    <ListItem
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push({
                          pathname: "/terms-conditions",
                        });
                      }}
                    >
                      <Link target="_blank">
                        Terms & Conditions
                      </Link>

                    </ListItem>
                    <ListItem
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push({
                          pathname: "/privacy-policy",
                        });
                      }}
                    >
                      <Link target="_blank">
                        Privacy Policy
                      </Link>

                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Typography variant="h6">Contact Us</Typography>
              <List>
                <ListItem>
                  <Link
                    target="_blank"
                    href="https://www.mobiloitte.com/"
                  >
                    <AiOutlineMail />

                    connect@mobiloitte.com
                  </Link>
                </ListItem>
              </List>
              <Box>
                <IconButton>
                  <Link
                    target="_blank"
                    href="https://www.facebook.com/login/"
                  >
                    <FaFacebookF />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link
                    target="_blank"
                    href="https://www.youtube.com/"
                  >
                    <FiYoutube />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link
                    target="_blank"
                    href="https://www.instagram.com/accounts/login/"
                  >
                    <FaInstagram />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link target="_blank"
                    href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D"
                  >
                    <FiTwitter />
                  </Link>
                </IconButton>
                <IconButton>
                  <Link
                    target="_blank"
                    href="https://www.reddit.com/"
                  >
                    <AiOutlineReddit style={{ fontSize: "20px" }} />
                  </Link>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box className="copy" mt={1}>
          Mobiloitte Group © 2023. All Rights Reserved
        </Box>
      </Box>
    </>
  );
}
